import isURL from 'validator/lib/isURL';
import isIBAN from 'validator/lib/isIBAN';
import isStrongPassword from 'validator/lib/isStrongPassword';

export const postalCodeValidator = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (/^[1-9][0-9]{3}[\040|\x20]*[A-Za-z]{2}$/i.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject("Postcode format niet correct (Correct voorbeeld: 3333AA)");
};

export const ibanValidator = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (isIBAN(value)) {
        return Promise.resolve();
    }
    return Promise.reject("Vul een valide IBAN in");
};

export const phoneNumberValidator = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (/(^0[0-9]{9}$)/i.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject("Vul een valide telefoonnummer in (voorbeeld 0612345678)");
};

export const integerValidator = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (/^[0-9]\d*$/i.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject("Mag alleen numerieke tekens bevatten");
};

export const amountValidator = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (/^[0-9]+\.?[0-9]*$/i.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject("Vul een valide bedrag in bijv. 10.00");
};


export const cocNumberValidator = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (/^[0-9]{8}$/i.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject("Vul een valide kVk-nummer in");
};

export const alphaValidator = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (/^[0-9]{8}$/i.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject("Mag alleen alfanumeriek tekens bevatten");
};

export const maxlenghtValidator11 = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (/^[a-zA-Z]{11}$/.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(`Mag maximaal 11 tekens bevatten`);
};

export const websiteValidator = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (isURL(value)) {
        return Promise.resolve();
    }
    return Promise.reject(`Vul een valide url in`);
};


export const strongPasswordValidator = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (isStrongPassword(value)) {
        return Promise.resolve();
    }
    return Promise.reject('Zorg dat het wachtwoord tenminste 8 karakters bevat waarvan 1 hoofdletter, 1 nummer en 1 speciaal teken');
};

export const checkCheckBox = (rule, value) => {
    if (value === null || value === undefined || value === '') {
        return Promise.resolve(false);
    }
    if (value) {
        return Promise.resolve();
    }
    return Promise.reject('Ga akkoord met de voorwaarden');
};
